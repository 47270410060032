<template>
  <transition name="fade">
    <div class="my-overlay">
      <v-row
        class="fill-height light"
        align="center"
        justify="center"
      >
        <div>
          <v-img
            src="loader_anim.gif"
          />
        </div>
      </v-row>
    </div>
  </transition>
</template>
<script>
  export default {
    name: 'LoadingOverlay',
  }
</script>
<style scoped>
.my-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0,0,0,0);
}

.fade-enter-active, .fade-enter-leave {
  transition: opacity .1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
